import React from 'react'
import { graphql } from 'gatsby'
import { usePreview } from '@kogk/gatsby-theme-prismic'
import MainLayout from '@cmp/layout/MainLayout/MainLayout'
import { GlobalDataProvider } from '@src/data/global-data'
import { pageResolver } from '@prismic/lib/dataResolvers'
import '@prismic/fragments/page'
import SliceMapper from '@prismic/lib/slice-mapping'

interface Props {
  data: any
}

const Page = ({ data: staticData }: Props) => {
  const data = usePreview(staticData)

  const page = pageResolver(data.prismicPage)

  return (
    <GlobalDataProvider language='en' altUrls={[]}>
      <MainLayout>
        <article className='page-wrap'>
          {page.body.map((slice, idx) => (
            <SliceMapper idx={idx} key={idx} slice={slice} />
          ))}
        </article>
      </MainLayout>
    </GlobalDataProvider>
  )
}

export default Page

export const query = graphql`
  query genericPageQuery($prismicId: ID!) {
    prismicPage(prismicId: { eq: $prismicId }) {
      ...prismicPageFragmentFull
    }
  }
`
