import React from 'react'
import { Draft } from '@kogk/gatsby-theme-prismic'
import page from '@cmp/templates/Page'
import event from '@cmp/templates/Event'

const typeMap = {
  page,
  event
}

export default () => <Draft typeMap={typeMap} />
