import React from 'react'
import { graphql } from 'gatsby'
import { usePreview } from '@kogk/gatsby-theme-prismic'
import MainLayout from '@cmp/layout/MainLayout/MainLayout'
import { GlobalDataProvider } from '@src/data/global-data'
import { eventResolver } from '@prismic/lib/dataResolvers'
import '@prismic/fragments/event'
import SliceMapper from '@prismic/lib/slice-mapping'
import { Icon } from '@islandsstofa/react'

type Props = {
  data: {
    prismicPage: any
  }
}

const Event = ({ data: staticData }: Props) => {
  const data = usePreview(staticData)
  const event = eventResolver(data.prismicEvent)

  // All dates should be shown in GMT timezone.
  // It is important to include timezone since new Date() adds time to the date string
  // and that affects how the date is formatted, e.g. June 20 might be shown as June 19 in the USA.
  const date = Intl.DateTimeFormat('en-US', {
    month: 'long',
    day: '2-digit',
    year: 'numeric',
    timeZone: 'GMT'
  }).format(new Date(event.from))

  return (
    <GlobalDataProvider language='en' altUrls={[]}>
      <MainLayout>
        <article className='page-wrap'>
          <div className='container mb-4 single-event__detail-box'>
            <div className='d-flex mb-2'>
              {event.time_from && (
                <div className='d-flex single-event__detail-box__date mr-2'>
                  <Icon type='clock' className='mr-1 mt-n1' />
                  <p className='input-label mb-0'>{event.time_from}</p>
                </div>
              )}
              <div className='d-flex single-event__detail-box__date'>
                <Icon className='mt-n1 mr-1' type='calendar' />
                <p className='input-label mb-0'>{date}</p>
              </div>
            </div>

            <h1 className='mb-2'>{event.title}</h1>
          </div>
          {event.body.map((slice, key) => (
            <SliceMapper key={key} slice={slice} idx={key} />
          ))}
        </article>
      </MainLayout>
    </GlobalDataProvider>
  )
}

export default Event

export const query = graphql`
  query genericEventQuery($prismicId: ID!) {
    prismicEvent(prismicId: { eq: $prismicId }) {
      ...prismicEventFragmentFull
    }
  }
`
